<template>
  <div class="affixion-contain">
    <Form :label-width="115">
      <Row>
        <Col span="8" offset="8">
          <Form-item label="日期：">
            <Date-picker type="date" :options="options" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
          </Form-item>
          <Form-item label="采集时间：">
            <Time-picker confirm type="time" placeholder="选择时间" @on-change="handleChangeTime"></Time-picker>
          </Form-item>
          <Form-item label="闭眼左平衡：">
            <Row>
              <Col :span="23"><Input v-model="balancedData.open" placeholder="请输入闭眼左平衡值"></Input></Col>
              <Col :span="1">&nbsp;&nbsp;S</Col>
            </Row>
          </Form-item>
          <Form-item label="闭眼右平衡：">
            <Row>
              <Col :span="23"><Input v-model="balancedData.close" placeholder="请输入闭眼右平衡值"></Input></Col>
              <Col :span="1">&nbsp;&nbsp;S</Col>
            </Row>
          </Form-item>
        </Col>
      </Row>
      <div class="symbol-box tac">
        <Button type="primary" size="large" @click="handleBack">返回</Button>
        <Button type="success" size="large" @click="handleSave">保存</Button>
      </div>
    </Form>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			balancedData: {
				test_date: '',
				test_time: '',
				open: '',
				close: '',
			},
			options: {
				disabledDate(date) {
					return date && date.valueOf() >= Date.now();
				},
			},
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	methods: {
		handleBack() {
			this.$router.go(-1);
		},
		handleSave() {
			if (this.validateFormData()) {
				this.balancedData.member_id = this.memberId;
				MemberService.balancedAdd(this.balancedData).then(() => {
					this.$router.go(-1);
				});
			}
		},
		handleChangeDate(value) {
			this.balancedData.test_date = value;
		},
		handleChangeTime(value) {
			this.balancedData.test_time = value;
		},
		isNumber(arg) {
			return typeof parseFloat(arg) === 'number';
		},
		isPositiveNumber(value) {
			return this.isNumber(value) && value >= 0;
		},
		validateFormData() {
			if (!this.balancedData.test_date) {
				this.$Message.warning('请选择日期');
				return false;
			}
			if (!this.balancedData.test_time) {
				this.$Message.warning('请选择采集时间');
				return false;
			}
			if (this.balancedData.open === '') {
				this.$Message.warning('请输入闭眼左平衡值');
				return false;
			} else if (!this.isPositiveNumber(this.balancedData.open)) {
				this.$Message.warning('请输入闭眼左平衡有效值');
				return false;
			}
			if (this.balancedData.close === '') {
				this.$Message.warning('请输入闭眼右平衡值');
				return false;
			} else if (!this.isPositiveNumber(this.balancedData.close)) {
				this.$Message.warning('请输入闭眼右平衡有效值');
				return false;
			}
			return true;
		},
	},
};
</script>
<style lang="css" scoped>
.affixion-contain {
  margin-top: 40px;
}
</style>
